import { connect } from 'react-redux';
import { ChangeForgotPassword } from '../../components/ChangeForgotPassword';
import { updateUserForgotPassword } from '../../store/actions/user';
import { clearError } from '../../store/actions/error';
import { setSuccessChangePw } from '../../store/actions/authentification';


const mapStateToProps = (state) => ({
  error: state.error.error,
  errorMessages: state.error.errorMessages,
  successChangePw: state.auth.successChangePw,
});

const mapDispatchToProps = (dispatch) => ({
  
  updateUser: (newPassword, token, email) => {
    dispatch(updateUserForgotPassword(newPassword, token, email));
  },

  clearError: () => {
    dispatch(clearError());
  },

  setSuccessChangePw: (value) => {
    dispatch(setSuccessChangePw(value));
  }
  
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangeForgotPassword);