import { connect } from 'react-redux';
import { Home } from '../../components/Home';


const mapStateToProps = (state, ownProps) => ({
  isLogged: state.auth.isLogged,
});

const mapDispatchToProps = (dispatch, ownProps) => ({

});


export default connect(mapStateToProps, mapDispatchToProps)(Home);
