import api from './utils/api';

import {
  UPDATE_USER,
  ADD_NEW_USER,
  FETCH_CLIENTS_OF_PHOTOGRAPHER,
  saveAllClientsOfPhotographer,
  fetchClientsOfPhotographer,
  UPDATE_USER_FORGOT_PASSWORD
} from '../actions/user';

import { setErrorMessage } from '../actions/error';
import { saveUser, setSuccessChangePw } from '../actions/authentification';



const usermiddleware = (store) => (next) => (action) => {
  switch (action.type) {
    case UPDATE_USER: {
        const { userId, oldPassword, newPassword } = action;
      api({
        method: 'PATCH',
        url: `user/${userId}/password`,
        data: {
            oldPassword,
            newPassword
          }
        })
        .then((response) => {
          store.dispatch(setSuccessChangePw());
          store.dispatch(saveUser(response.data));
        })
        .catch((error) => {
          store.dispatch(setErrorMessage(error.response.data.message));
          return Promise.reject(error);
        });
      break;
    }

  case ADD_NEW_USER: {
    const state = store.getState();
    api({
      method: 'POST',
      url: `auth/signup`,
      data: {
        ...action.newClient,
        userId: state.auth.id,
      }
    })
      .then((response) => {
        store.dispatch(fetchClientsOfPhotographer());
      })
      .catch((error) => {
          console.log(error)
        });
   
    break; 
  }

  case FETCH_CLIENTS_OF_PHOTOGRAPHER: {
    api({
      method: 'GET',
      url: `client`,
    })
      .then((response) => {
        store.dispatch(saveAllClientsOfPhotographer(response.data));
      })
      .catch((error) => {
          console.log(error)
        });
    break;
  }

  case UPDATE_USER_FORGOT_PASSWORD: {
    const { newPassword, token, email } = action;
    api({
      method: 'PATCH',
      url: `auth/reset-password`,
      data: {
        newPassword,
        resetPasswordToken: token,
        email
      }
    })
      .then((response) => {
        store.dispatch(setSuccessChangePw());
        store.dispatch(saveUser(response.data));
      })
      .catch((error) => {
        store.dispatch(setErrorMessage(error.response.data.message));
        return Promise.reject(error);
      });
    break;
  }


  default:
    next(action);


  }
};

export default usermiddleware;
