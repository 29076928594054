import React from 'react';
// import PropTypes from 'prop-types';
import './styles.scss';
import image from './../../../assets/images/its-me2.jpg';


export const ItsMeHome = () => {

  return (
    <div className='its-me-home'>
      <img src={image} alt="laura huisman" className='its-me-home__image'/>
      <div className='its-me-home__text'> 
        <p className='its-me-home__text__title'> Je suis heureuse de vous accueillir ici. </p>
        <p>Photographe depuis 2017, je suis spécialisée dans la maternité, la naissance et la famille.</p>
        <p>Étant jeune maman, j’ai conscience de l’importance de capturer les jolis moments de vie.</p>

        <p>Je propose des séances chez vous, dans votre petit cocon, en extérieur ou dans un lieu qui vous tient à coeur.</p>

        <p>Si vous voulez en savoir plus sur moi, n’hésitez pas à cliquer ci-dessous.</p>

        <a className='myButton' href="/c-est-moi">Plus d'infos</a> 
      
      </div>
          
    </div>
  );
};

ItsMeHome.propTypes = {
};

// Valeurs par défaut pour les props
ItsMeHome.defaultProps = {
};

