import React, { useState } from 'react';


// import PropTypes from 'prop-types';

import './styles.scss';


export const Rates = ({
  theme,
  isPhotographer,
  addRate,
  deleteRate,
}) => {

  const [showForm, setShowForm] = useState(false);
  const [newRate, setNewRate] = useState({ nbPhotos: '', price: '' });


  const toggleForm = () => {
    setShowForm(!showForm);
  };

  const handleAdd = (themeId) => {
    addRate(newRate, themeId);
    setNewRate({ nbPhotos: '', price: '' });
    setShowForm(false);
  };

  const handleDelete = (rate, themeId) => {
    deleteRate(rate, themeId);
  };



  return (

    <div className='rates'>
        <p className='rates__header'> Tarifs : </p>

        {theme.rates.sort((a, b) => a.nbPhotos - b.nbPhotos).map((rate, index) => (
            <div key={index}>
                <div className='rates__rate'><b>{rate.nbPhotos}</b> photos : <span className='rates__rate__amount'>{rate.price} €</span></div>
                { isPhotographer && 
                    <i className='bi bi-trash text-danger' onClick={() => handleDelete(rate, theme.id)}></i>
                }
            </div>
        ))}

        <div>
            {isPhotographer && !showForm && (
                <button
                onClick={() => toggleForm()}
                className='myButton mt-3'
            >
                <i className='bi bi-plus-lg'> Ajouter </i>
            </button>
            )}

        {isPhotographer && showForm && (
            <div>
                <input
                    type='number'
                    placeholder='Nombre de Photos'
                    value={newRate.nbPhotos}
                    onChange={(e) =>
                    setNewRate({
                        ...newRate,
                        nbPhotos: parseInt(e.target.value, 10),
                    })
                    }
                />
                <input
                    type='number'
                    placeholder='Prix'
                    value={newRate.price}
                    onChange={(e) =>
                    setNewRate({
                        ...newRate,
                        price: parseFloat(e.target.value),
                        })
                    }
                />
                <div className='newClient__form__buttons'>
                    <button
                        onClick={() => handleAdd(theme.id)}
                        className='mySmallButton bg-success newClient__form__buttons__button'
                    >
                        Valider
                    </button>
                    <button
                        type='button'
                        onClick={() => toggleForm()}
                        className='mySmallButton bg-danger newClient__form__buttons__button'
                    >
                        Annuler
                    </button>
                </div>
            </div>
        )}

        </div> 
    </div>
  );
};

Rates.propTypes = {
};

// Valeurs par défaut pour les props
Rates.defaultProps = {
};