import { connect } from 'react-redux';
import { Themes } from '../../components/Themes';


const mapStateToProps = (state, ownProps) => ({
    themes: state.theme.themes,
    isLogged: state.auth.isLogged,
});

const mapDispatchToProps = (dispatch, ownProps) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(Themes);
