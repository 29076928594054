import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import './styles.scss';
import { Container, Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
//import _ from 'lodash';

import logo from './../../assets/images/logo_white.png';


export const NavBar = ({
  FetchThemesByPhotographer,
  isLogged,
  logout,
  firstName,
  isPhotographer,
}) => {
  // fetch all shooting pages on firts render
  useEffect(() => {
    FetchThemesByPhotographer();
    const handleScroll = () => {
      const isScrolled = window.scrollY > 100; 
      setScrolled(isScrolled);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [
    FetchThemesByPhotographer,
  ]);

    let location = useLocation(); 
    let navigate = useNavigate();

    const [scrolled, setScrolled] = useState(false);


    const handleFetchItsMePage = (evt) => {
      evt.preventDefault();
      setTimeout(() => {
        navigate('/c-est-moi');
      }, 300);
    }

    const handleLogout = (evt) => {
      evt.preventDefault();
      logout();
    }


  return (
      <Navbar expand="lg" fixed='top' className={scrolled ? 'navbar__scrolled' : ''}>
        <Container>
          <Navbar.Toggle />
          <Navbar.Collapse>
            <Nav className="navbar mx-auto">
              <Nav.Link className={location.pathname === "/seances" ? "navbar__link active" : "navbar__link"} href="/seances">Séances</Nav.Link>
              <Nav.Link className={location.pathname === "/its_me" ? "navbar__link active" : "navbar__link"} onClick={handleFetchItsMePage} href="/c-est-moi">C'est moi</Nav.Link>
              <Nav.Link href="/"> <img src={logo} alt="logo" className='navbar__logo'/> </Nav.Link>
              <Nav.Link className={location.pathname === "/contact" ? "navbar__link active" : "navbar__link"} href="/contact">Contact</Nav.Link>

              {!isLogged && 
                <Nav.Link className={location.pathname === "/login" ? "navbar__link active" : "navbar__link"} href="/login">Se connecter</Nav.Link>   
              }
              {isLogged &&
                <NavDropdown title={'Bonjour ' + firstName} id="basic-nav-dropdown">
                  <NavDropdown.Item href="/galeries">Mes galeries</NavDropdown.Item>
                  {isPhotographer && 
                    <NavDropdown.Item href="/clients">Mes clients </NavDropdown.Item>
                  }
                  <NavDropdown.Item href="/mon-compte/:id">Mon compte</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item onClick={handleLogout}> Se déconnecter </NavDropdown.Item>
                </NavDropdown>
              }
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
  )};


