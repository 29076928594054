import {
  saveShootings,
  ADD_NEW_SHOOTING,
  DELETE_SHOOTING,
  FETCH_SHOOTING_BY_ID,
  saveShooting,
  VALIDATE_FAVOURITES,
  FETCH_SHOOTINGS_BY_USER,
  fetchShootingsByUser,
  UPDATE_SHOOTING,
  fetchShooting,
} from '../actions/shooting';


import api from './utils/api';

const shootingmiddleware = (store) => (next) => (action) => {
  switch (action.type) {
    case FETCH_SHOOTINGS_BY_USER: {
      const state = store.getState();
      const userId = state.auth.id;
      api({
        method: 'GET',
        url: `shooting/user/${userId}`
      })
        .then((response) => {
          store.dispatch(saveShootings(response.data));
        })
        .catch((error) => {
            console.log(error)
          });
      break;
    }

    case ADD_NEW_SHOOTING: {
      const state = store.getState();
      api({
        method: 'POST',
        url: `shooting`,
        data:{
          ...action.newShooting,
          photographerId: state.auth.photographer.id
        }

      })
        .then((response) => {
          store.dispatch(fetchShootingsByUser(state.auth.id))
        })
        .catch((error) => {
            console.log(error)
          });
      break;
    }

    case DELETE_SHOOTING: {
      const state = store.getState();
      api({
        method: 'DELETE',
        url: `shooting/${action.shootingId}`,

      })
        .then((response) => {
          store.dispatch(fetchShootingsByUser(state.auth.id))
        })
        .catch((error) => {
            console.log(error)
          });
      break;
    }

    case FETCH_SHOOTING_BY_ID: {
      let state = store.getState();
      let userId = state.auth.id;
      let id = action.id;
        api({
          method: 'GET',
          url: `shooting/${id}`,
          params: {
            userId: userId,
          }
        })
        .then((response) => {
          store.dispatch(saveShooting(response.data));
        })
        .catch((error) => {
          console.log(error)
        })
      break;
    }

    case VALIDATE_FAVOURITES: {
      api({
        method: 'PATCH',
        url: `shooting/${action.shootingId}`,
        data: {
          favouritesSelected: true,
        }
      })
        .then((response) => {
          store.dispatch(fetchShootingsByUser(action.userId))
        })
        .catch((error) => {
            console.log(error)
          })
      break;
    }

    case UPDATE_SHOOTING: {
      api({
        method: 'PATCH',
        url: `shooting/${action.id}`,
        data: {
          numberOfPictures: action.newNumberOfPictures,
        }
      })
        .then((response) => {
          store.dispatch(fetchShooting(action.id))
        })
        .catch((error) => {
            console.log(error)
          })
      break;
    }


    default:
      next(action);


  }
};

export default shootingmiddleware;
