export const FETCH_THEMES_BY_PHOTOGRAPHER = 'FETCH_THEMES_BY_PHOTOGRAPHER';
export const SAVE_THEMES = 'SAVE_THEMES';
export const EDIT_THEME = 'EDIT_THEME';


export const FetchThemesByPhotographer = () => ({
    type: 'FETCH_THEMES_BY_PHOTOGRAPHER',
});

export const saveThemes = (themes) => ({
    type: 'SAVE_THEMES',
    themes
});

export const editTheme = (themeId, field, value) => ({
    type: 'EDIT_THEME',
    themeId,
    field,
    value,
})

