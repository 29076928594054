import {
    FETCH_THEMES_BY_PHOTOGRAPHER,
    saveThemes,
    EDIT_THEME,
    FetchThemesByPhotographer,
  } from '../actions/theme';
  
  import api from './utils/api';
  
  const thememiddleware = (store) => (next) => (action) => {
    switch (action.type) {
      case FETCH_THEMES_BY_PHOTOGRAPHER: {
        api({
          method: 'GET',
          url: `theme/photographer/${process.env.REACT_APP_PHOTOGRAPHER_ID}`,
  
        })
          .then((response) => {
            store.dispatch(saveThemes(response.data));
          })
          .catch((error) => {
              console.log(error)
            });
        break;
      }

      case EDIT_THEME: {
        api({
          method: 'PATCH',
          url: `theme/${action.themeId}`,
          data: {
            [action.field]: action.value,
          },
        })
          .then((response) => {
            store.dispatch(FetchThemesByPhotographer());
          })
          .catch((error) => {
              console.log(error)
            });
        break;
      }
  
  
      default:
        next(action);
    }
  };
  
  export default thememiddleware;
  