import {
  LOGIN,
  saveUser,
  showErrorLoginMessage,
  createForgotErrorAction,
  createForgotAction,
  FORGOT_PASSWORD,
} from './../actions/authentification';
import { fetchShootingsByUser } from './../actions/shooting';
import { FetchThemesByPhotographer } from './../actions/theme';


import api from './utils/api';

const authmiddleware = (store) => (next) => (action) => {
  switch (action.type) {
    case LOGIN: {
      api({
        method: 'POST',
        url: 'auth/login',
        data: {
          username: action.email,
          password: action.password,
        },
      })
        .then((response) => {
          localStorage.setItem('token', response.data.token);
          localStorage.setItem('userId', response.data.user.id);
          const actionSaveUser = saveUser(response.data);
          store.dispatch(actionSaveUser);
          store.dispatch(fetchShootingsByUser(response.data.user.id));
          store.dispatch(FetchThemesByPhotographer()); 
        })
        .catch((error) => {
            console.log(error)
            const message = 'Erreur d\'identifiants';
            store.dispatch(showErrorLoginMessage(message));
          });
      break;
    }

    case FORGOT_PASSWORD: {
      api({
        method: 'POST',
        url: '/auth/forgot-password',
        data: {
          email: action.forgotEmail,
        },
      })
        .then((response) => {
          if (response.data === true ) {
          store.dispatch(createForgotAction());
          } else {
            store.dispatch(createForgotErrorAction());

          }
        })
        .catch((error) => {
          console.log(error);
        });
      break;
    }

    default:
      next(action);


  }
};

export default authmiddleware;
