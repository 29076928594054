import React, { useState, useEffect } from 'react';
import './styles.scss';

export const Footer = () => {
  const [isFixed, setIsFixed] = useState(true);

  const checkForVerticalScroll = () => {
    const hasVerticalScroll = document.body.scrollHeight > window.innerHeight;
    setIsFixed(!hasVerticalScroll);
  };

  useEffect(() => {
    checkForVerticalScroll();
    window.addEventListener('resize', checkForVerticalScroll);
    return () => {
      window.removeEventListener('resize', checkForVerticalScroll);
    };
  }, []);

  return (
    <div className={`footer ${isFixed ? 'fixed' : ''}`}>
      <div className='footer__left'>
        <p className='footer__left__text'>© {new Date().getFullYear()} Laura Huisman Photographe</p>
        <a href='/' className='footer__left__link'> Mentions légales </a>  
      </div> 
      <ul className="nav footer__right">
        suivez moi sur les réseaux
        <li className="nav-item">
          <a className="nav-link link-light" href="https://www.facebook.com/laurahuismanphotographe?locale=fr_FR" target='_blank' rel="noreferrer"><i className="bi bi-facebook"></i></a>
        </li>
        <li className="nav-item">
          <a className="nav-link link-light" href="https://www.instagram.com/laurahuismanphotographe/?utm_source=ig_web_button_share_sheet&igshid=OGQ5ZDc2ODk2ZA==" target='_blank' rel="noreferrer"><i className="bi bi-instagram"></i></a>
        </li>
      </ul>
    </div>
  );
};
