import {
  DELETE_PICTURE,
  UPLOAD_IMAGE,
  SET_FAVORITE,
  ADD_PICTURE_ON_THEME,
} from '../actions/picture';

import { FetchThemesByPhotographer } from '../actions/theme';

import {
  fetchShooting,
} from '../actions/shooting';

import api from './utils/api';

const picturemiddleware = (store) => (next) => (action) => {
  switch (action.type) {
    case DELETE_PICTURE: {
      api({
        method: 'DELETE',
        url: `picture/${action.pictureId}`,
      })
        .then((response) => {
          action.shootingId && store.dispatch(fetchShooting(action.shootingId));
          action.themeId && store.dispatch(FetchThemesByPhotographer());
        })
        .catch((error) => {
            console.log(error)
          });
      break;
    }

    case UPLOAD_IMAGE: {
      api({
        method: 'POST',
        url: `picture/shooting/${action.shootingId}/upload`,
        data: action.formData,
        timeout: 0,
        ...action.config,
        headers: {
          'Content-Type': 'multipart/form-data'
          },     
      })
        .then((response) => {
          store.dispatch(fetchShooting(action.shootingId));
        })
        .catch((error) => {
            console.log(error)
          });
      break;
    }

    case SET_FAVORITE: {
      api({
        method: 'PATCH',
        url: `picture/${action.pictureId}`,
        data: {
          // set isFavorite in the opposite of the current value
          isFavorite: !store.getState().shooting.shooting.pictures.find(picture => picture.id === Number(action.pictureId)).isFavorite,
        },
      })
        .then((response) => {
          window.location.reload(false);
      })
        .catch((error) => {
            console.log(error)
          });
      break;
    }

    case ADD_PICTURE_ON_THEME: {
      api({
        method: 'POST',
        url: `picture/theme/${action.themeId}/upload/${action.isMainPicture}`,
        data: action.formData,
        timeout: 0,
        headers: {
          'Content-Type': 'multipart/form-data'
          },     
      })
        .then((response) => {
          store.dispatch(FetchThemesByPhotographer());
        })
        .catch((error) => {
            console.log(error)
          });
      break;
    }

    default:
      next(action);


  }
};

export default picturemiddleware;
