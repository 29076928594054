import {
    DELETE_RATE,
    ADD_RATE  
  } from '../actions/rate';

  import { FetchThemesByPhotographer } from '../actions/theme';
  
  import api from './utils/api';
  
  const ratemiddleware = (store) => (next) => (action) => {
    switch (action.type) {

      case DELETE_RATE: {
        const rate = action.rate;
        const themeId = action.themeId;
        api({
          method: 'DELETE',
          url: `rate/${rate.id}/theme/${themeId}`,
        })
          .then((response) => {
            store.dispatch(FetchThemesByPhotographer());
          })
          .catch((error) => {
              console.log(error)
            });
        break;
      }

      case ADD_RATE: {
        const rate = action.rate;
        const themeId = action.themeId;
        api({
          method: 'POST',
          url: `rate/${themeId}`,
          data: { ...rate },
        })
          .then((response) => {
            store.dispatch(FetchThemesByPhotographer());
          })
          .catch((error) => {
            console.log(error);
          });
        break;
      }
  
  
      default:
        next(action);
    }
  };
  
  export default ratemiddleware;
  