import React from 'react';
// import PropTypes from 'prop-types';

import './styles.scss';
import ChangePassword from '../../containers/ChangePassword';

export const Account = ({
  user,
  isClient,
}) => {

  return (
    <div className='account'>
      <div className='account__title'> Mes informations</div>
      <b> {user.firstName} {user.lastName} </b>
      <div> {user.email}</div>
      { isClient && 
        <>
        <div> {user.client.address} - {user.client.postalCode} {user.client.city}</div>
        <div> {user.client.phoneNumber} </div>
        </>
      }
      
      <ChangePassword />
      
    </div>

    
  );
};

Account.propTypes = {
};

// Valeurs par défaut pour les props
Account.defaultProps = {
};
