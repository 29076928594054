import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import App from '../src/containers/App';
import Store from './store';
import { PersistGate } from 'redux-persist/integration/react'

// eslint-disable-next-line
import 'bootstrap/dist/css/bootstrap.min.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={Store.store}>
      <PersistGate loading={null} persistor={Store.persistor}>
        <Router >
          <App />
        </Router>
      </PersistGate>
    </Provider>
);


