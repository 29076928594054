import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import './styles.scss';

import Home from './../../containers/Home';
import Contact from './../../components/Contact';
import Login from './../../containers/Login';
import Galleries from '../../containers/Galleries';
import Shooting from '../../containers/Shooting';
import Account from '../../containers/Account';
import ChangeTemporaryPassword from '../../containers/ChangeTemporaryPassword';
import { ItsMe } from '../ItsMe';
import Clients from '../../containers/Clients';
import ChangeForgotPassword from '../../containers/ChangeForgotPassword';
import NavBar from '../../containers/NavBar';

import { Footer } from '../Footer';
import Theme from '../../containers/Theme';
import Themes from '../../containers/Themes';

import _ from 'lodash';

export function App({
  isLogged,
  themes,
  isPhotographer,
  isClient
}) {

  
  return (
    <div className="app">
        <NavBar />
        <Routes>
          <Route path="/" element={ <Home />} />
          <Route path='login' element={<Login />} />
          <Route path='reinitialiser-mot-de-passe/:token/:email' element={<ChangeForgotPassword />} />
          <Route path='contact' element={<Contact />} />
          <Route path='seances' element={<Themes />} />
          {themes.map((theme, index) => (
            <Route key={index} path={`/seance/${_.kebabCase(theme.name)}`} element={<Theme theme={theme} />} />
          ))}
          
          <Route path='c-est-moi' element={<ItsMe />} />

          {isLogged && <Route path='galeries' element={<Galleries />} />}
          {isLogged && <Route path='galerie/:id' element={<Shooting />} />}
          {isLogged && <Route path='mon-compte/:id' element={<Account />} />}
          {isLogged && isPhotographer && <Route path='clients' element={<Clients />} />}

          {isLogged && isClient && <Route path='temp' element={<ChangeTemporaryPassword />} />}


          <Route path='*' element={<Navigate to="/" replace />} />

        </Routes>

        <Footer />

    </div>
  );
}

App.propTypes = {
};

App.defaultProps = {
};

