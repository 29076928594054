import { connect } from 'react-redux';
import { Galleries } from '../../components/Galleries';
import { addNewUser } from '../../store/actions/user';
import { fetchShooting, deleteShooting, addNewShooting, setShootingId, fetchShootingsByUser } from '../../store/actions/shooting';
import { fetchClientsOfPhotographer } from '../../store/actions/user';


const mapStateToProps = (state, ownProps) => ({
  isLogged: state.auth.isLogged,
  shootings: state.shooting.shootings,
  firstConnect: state.auth.client ? state.auth.client.firstConnect : false,
  shooting: state.shooting.shooting,
  isPhotographer: state.auth.isPhotographer,
  isClient: state.auth.isClient,
  firstName: state.auth.firstName,
  id: state.auth.id,
  clients: state.user.clients,
  themes: state.theme.themes

});

const mapDispatchToProps = (dispatch, ownProps) => ({
  
  fetchShooting: (shootingId) => {
    dispatch(fetchShooting(shootingId))
  },

  deleteShooting: (shootingId) => {
    dispatch(deleteShooting(shootingId))
  },

  addNewUser: (newClient) => {
    dispatch(addNewUser(newClient));
  },

  addNewShooting : (newShooting) => {
    dispatch(addNewShooting(newShooting));
  },
  
  setShootingId: (shootingId) => {
    dispatch(setShootingId(shootingId))
  },

  fetchClients: () => {
    const photographerId = localStorage.getItem('photographerId');
    dispatch(fetchClientsOfPhotographer(photographerId))
  },

  fetchShootingsByUser: () => {
    dispatch(fetchShootingsByUser())
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Galleries);
