import { combineReducers } from 'redux';

import authReducer from './authentification';
import shootingReducer from './shooting';
import userReducer from './user';
import themeReducer from './theme';
import errorReducer from './error';


const rootReducer = combineReducers({
  // all reducers here
  auth: authReducer,
  shooting: shootingReducer,
  user: userReducer,
  theme: themeReducer,
  error: errorReducer,

});

export default rootReducer;
