import React from 'react';
//import PropTypes from 'prop-types';
import image from './../../../assets/images/home_long.jpg';

import './styles.scss';

export const LongImageHome = () => {

  return (
  
    <div className='longImageHome'>
      <img src={image} alt="deux frères" className='longImageHome__image'/>
    </div>
  
  )};

LongImageHome.propTypes = {
};

LongImageHome.defaultProps = {
};