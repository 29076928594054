import React from 'react';
import { useNavigate } from 'react-router-dom';
// import PropTypes from 'prop-types';
import _ from 'lodash';
import { Image } from 'cloudinary-react';

import './styles.scss';


export const Themes = ({
  themes
}) => {

  let navigate = useNavigate();

  themes.map((theme) => {
    theme.mainPicture = theme.pictures.find((picture) => picture.id === theme.mainPictureId);
    return theme;
  });

  return (

    <div className='themes'>
      {themes.map((theme, index) => (
        <div key={index} className='themes__theme' onClick={() => navigate(`/seance/${_.kebabCase(theme.name)}`)}>
          {theme.mainPicture ? (
              <Image publicId={theme.mainPicture.publicId} cloudName={ process.env.REACT_APP_CLN_CLOUD_NAME } className='themes__theme__picture' alt={theme.name} ></Image>
          ) : (
            <img src='https://placeholder.pics/svg/300x400' alt='à venir' />
          )}
          <div className='themes__theme__title'>
            {theme.name}
          </div>
        </div>
      ))}
    </div>
  );
};

Themes.propTypes = {
};

Themes.defaultProps = {
};
