import React, { useEffect, useState } from 'react';
import MediaQuery from 'react-responsive';
import { useParams } from 'react-router-dom';
import "yet-another-react-lightbox/styles.css";
import './styles.scss';
import Gallery from '../../containers/Gallery';
import AddPicture from '../../containers/AddPicture';
import Loading from '../Loading';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';


export const Shooting = ({
  isClient,
  isPhotographer,
  shooting,
  isLoading,
  fetchShooting,
  favouritesSelected,
  updateShooting,
  deletePicture,

}) => {

  const [showFormUpdateShooting, setShowFormUpdateShooting] = useState(false);
  const [newNumberOfPictures, setNewNumberOfPictures] = useState(shooting.numberOfPictures);

  let {id} = useParams();

  useEffect(() => {
    fetchShooting(id);
  }, [
    fetchShooting,
    id,
  ]);

  if (Object.keys(shooting).length === 0 && shooting.constructor === Object) {
    return <Loading />;
  }

  function isObjectNotEmpty(obj) {
    return Object.keys(obj).length > 0;
  }

  const handleDownloadAllImages = async () => {
    const zip = new JSZip();
    const folder = zip.folder("images");
    const pictures = shooting.pictures;
    const picturesLength = pictures.length;
    for (let i = 0; i < picturesLength; i++) {
      const picture = pictures[i];
      const response = await fetch(picture.secureUrl);
      const blob = await response.blob();
      folder.file(picture.originalName, blob);
    }
    zip.generateAsync({type:"blob"})
      .then(function(content) {
        saveAs(content, "images.zip");
      });
  };

  const handleShowFormUpdateShooting = () => {
    setShowFormUpdateShooting(true);
  }

  const handleUpdateShooting = (evt) => {
    evt.preventDefault();
    updateShooting(id, newNumberOfPictures);
    setShowFormUpdateShooting(false);
  }

  const handleChangeNumberOfPictures = (evt) => {
    setNewNumberOfPictures(evt.target.value);
  }



  return (
    <>
      {isLoading ? (
          <Loading />
        ) : (
        
          <div className='shooting'>

            <div className='shooting__header'> 
              
                <div>
                  <h2 className='shooting__header__title'> {shooting.nameOfGallery} - Galerie </h2>
                  <div className='shooting__header__name'> {isPhotographer ? <p>{isObjectNotEmpty(shooting) && shooting.client.user.firstName} {isObjectNotEmpty(shooting) && shooting.client.user.lastName}</p> : null} </div>
                </div>
                
                { !favouritesSelected && 
                  <div> 
                    { shooting && isPhotographer && <div> Nombre de photos voulues: { shooting.numberOfPictures} <i className='bi-pencil' onClick={handleShowFormUpdateShooting}></i> </div> }
                    { shooting && isPhotographer && showFormUpdateShooting &&  
                      <form onSubmit={handleUpdateShooting}>
                        <input type='number' name='numberOfPictures' value={newNumberOfPictures} onChange={handleChangeNumberOfPictures} min={0} />
                        <button type='submit'> OK </button>
                      </form> 
                    }
                    { shooting && isPhotographer && <div> Nombre de photos dans la galerie: { shooting.pictures.length } </div> } 
                  </div> 
                }

                { isClient && favouritesSelected && (
                  <button className='myButton' onClick={handleDownloadAllImages}>Télécharger toutes les images</button>
                )}
                

            </div>
            
              {isPhotographer  && shooting.nameOfFavourites === null && <AddPicture />}

              <MediaQuery minWidth={769}>
                <Gallery gallery={shooting.pictures} layout={"columns"} columns={3} deletePicture={deletePicture} />
              </MediaQuery>
              <MediaQuery maxWidth={768}>
                  <Gallery gallery={shooting.pictures} layout={"columns"} columns={1} deletePicture={deletePicture}/>
              </MediaQuery>  

          </div>
        )}
    </>
  );
};

Shooting.propTypes = {
};

Shooting.defaultProps = {
};