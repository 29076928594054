import React, { useState } from 'react';
import PhotoAlbum from 'react-photo-album';
import Lightbox from 'yet-another-react-lightbox';

import { findFavoritesOfShooting } from '../../utils/findFavoritesOfShooting';

import "yet-another-react-lightbox/styles.css";

import './styles.scss';
import Swal from 'sweetalert2';

export const Gallery = ({
  gallery,
  layout,
  columns,
  setFavorite,
  isPhotographer,
  isClient,
  deletePicture,
  shooting,
  validateFavourites,
  userId,
  favouritesSelected,
  theme
}) => { 
 
  const [index, setIndex] = useState(-1);

  const breakpoints = [4320, 2160, 1080, 640, 384, 256, 128];

  if(!gallery) {
    return null;
  }

  gallery.sort((a, b) => a.id - b.id);

  const photos = gallery.map((photo, index) => {
    const width = photo.width;
    const height = photo.height;
    const isFavorite = photo.isFavorite;
    return {
      src: `${photo.secureUrl}`,
      key: `${index}`,
      width,
      height,
      isFavorite,
      id: `${photo.id}`,
      images: breakpoints.map((breakpoint) => {
        const breakpointHeight = Math.round((height / width) * breakpoint);
        return {
          src: `${photo.secureUrl}`,
          width: breakpoint,
          height: breakpointHeight,
        };
      })
    };
  });

  if(theme) {
    photos.forEach((photo, index) => {
      if(Number(photo.id) === Number(theme.mainPictureId)) {
        photos.splice(index, 1);
      }
    });
  }

  const slides = photos.map(({ src, key, width, height, images }) => ({
    src,
    key,
    aspectRatio: width / height,
    srcSet: images?.map((image) => ({
      src: image.src,
      width: image.width
    }))
  }));

  const renderPhoto = ({ 
    imageProps: { alt, style, ...restImageProps },
    photo
  }) => { 

    return (      
    
      <div style={{ width: style?.width}}>

        <img alt={alt} style={{ ...style, width: "100%", padding: 0 }} {...restImageProps} />
        
        { isClient && !favouritesSelected && (
          <div className='gallery__buttons'>
          { !photo.isFavorite && (
            <div className='gallery__buttons__button' onClick={handleSetFavorite} id={photo.id}>
              <i className="bi bi-plus-lg gallery__button__icon" id={photo.id}></i>
              <span className='gallery__buttons__button__legend' id={photo.id}> Ajouter aux favorites </span>
            </div>  
            )
          }
          </div>
        )} 

        { isClient && favouritesSelected && (
          <button className='myButton' onClick={() => handleDownloadImage(photo)}><i className="bi bi-download"></i></button>
        )}


        { isPhotographer && (
          <div className='gallery__buttons'>
              <div className='gallery__buttons__button' onClick={handleDeletePicture} id={photo.id}> 
                <i className="bi bi-x-lg gallery__buttons__button__action" id={photo.id}></i> 
                <span className='gallery__buttons__button__legend' id={photo.id}> Supprimer </span>
              </div>
          </div>
        )}
      </div>
      
  )};

  let favorites = [];
  if(isClient) {
    favorites = findFavoritesOfShooting(shooting);
  }

  const handleSetFavorite = (evt) => {
    setFavorite(evt.target.id, shooting.id);
  }
  
  const handleDeletePicture = (evt) => {
    // demander confirmation avant de supprimer
    

    deletePicture(evt.target.id, shooting ? shooting.id : null, theme ? theme.id : null);
  }

  const handleValidateFavourites = () => {
    if(findFavoritesOfShooting(shooting).length === shooting.numberOfPictures) {
      Swal.fire({
        text: 'Cette action va vous permettre de télécharger vos photos, vous ne pourrez donc plus modifier votre sélection, êtes-vous sûr(e) ?',
        showDenyButton: true,
        confirmButtonText: `Valider`,
        denyButtonText: `Annuler`,
        icon: 'question',
      }).then((result) => {
        if (result.isConfirmed) {
          validateFavourites(shooting.id, userId);

          Swal.fire({
            title: 'Vos favorites ont bien été validées ! Vous allez pouvoir les télécharger tout de suite.',
            icon: 'success',
            confirmButtonText: 'Ok',
          }).then(() => {
            window.location.reload();
          })
        }
      })
    } else {
      Swal.fire({
        text: `Vous avez sélectionné ${findFavoritesOfShooting(shooting).length - shooting.numberOfPictures} photos en plus que le nombre prévu dans votre formule. Pas de panique ! N'hésitez pas à prendre contact avec moi afin d'augmenter le nombre de photos de votre forfait.`,
        icon: 'warning',
        confirmButtonText: 'Ok',
      })
    }
  }

  const handleDownloadImage = (image) => {
    fetch(image.src)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = shooting.nameOfGallery + '-' + image.key;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error('Erreur lors du téléchargement de l\'image : ', error);
      });
  };

  return (
  
      <div className='gallery'>
        <div className='gallery__container'>
          <div className='gallery__container__photoAlbum'>
            {isPhotographer && shooting && shooting.nameOfFavourites &&
              <div>
                <p> Cette séance photo a eu lieu il y a plus de 6 mois, les photos ont été supprimées. Voici le nom des photos sélectionnées : </p> 
                <p> {shooting.nameOfFavourites} </p>
              </div>
            }
            <PhotoAlbum
              layout={layout}
              columns={columns}
              photos={photos}
              targetRowHeight={150}
              onClick={(event, photo, index) => setIndex(index)}
              componentsProps={{ imageProps: { loading: "lazy" } }}
              renderPhoto={renderPhoto}
            />
          </div>

          {isClient && favorites.length > 0 && !favouritesSelected &&
            <div className='gallery__container__favorites'>
                <h3 className='text-center mb-3 text-black'> Je les veux ! </h3>
                {favorites.map((favorite, index) => {
                  return (
                    <div className='gallery__container__favorites__favorite' key={favorite.id}>
                      <span> {index +1 }. </span>
                      <img className='gallery__container__favorites__favorite__photo' src={favorite.secureUrl} alt={favorite.id} />
                      <div>
                        <p> { favorite.originalName }</p>
                        <button className='mySmallButton' onClick={handleSetFavorite} id={favorite.id}> Retirer des favorites </button>
                      </div> 
                    </div>
                    )
                  })
                }
                {isClient && findFavoritesOfShooting(shooting).length >= shooting.numberOfPictures  && 
                  <button className='myButton d-block mx-auto my-3' onClick={handleValidateFavourites}> Valider mes favorites </button>
                }
            </div>
          }

        </div>

        <Lightbox
          open={index >= 0}
          index={index}
          close={() => setIndex(-1)}
          slides={slides}
        />  
      </div>
  );
};

Gallery.propTypes = {
};

Gallery.defaultProps = {
};