export const UPLOAD_IMAGE = 'UPLOAD_IMAGE';
export const DELETE_PICTURE = 'DELETE_PICTURE';
export const SET_IS_LOADING = 'SET_IS_LOADING';
export const SET_FAVORITE = 'SET_FAVORITE';
export const ADD_PICTURE_ON_THEME = 'ADD_PICTURE_ON_THEME';


export const uploadImage = (formData, shootingId, config) => ({
  type: 'UPLOAD_IMAGE',
  formData,
  shootingId,
  config,
});

export const deletePicture = (pictureId, shootingId, themeId) => ({
  type: 'DELETE_PICTURE',
  pictureId,
  shootingId,
  themeId,
});

export const setIsLoading = () => ({
  type: 'SET_IS_LOADING',
});

export const setFavorite = (pictureId) => ({
  type: 'SET_FAVORITE',
  pictureId,
});

export const addPictureOnTheme = (formData, themeId, isMainPicture) => ({
  type: 'ADD_PICTURE_ON_THEME',
  formData,
  themeId,
  isMainPicture,
});


