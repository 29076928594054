import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import nl2br from 'react-nl2br';
import { Image } from 'cloudinary-react';
import { Rates } from './Rates';
// import PropTypes from 'prop-types';

import { Gallery } from '../Gallery';

import './styles.scss';
import _ from 'lodash';


export const Theme = ({
  theme,
  isPhotographer,
  editTheme,
  addPictureOnTheme,
  deletePicture,
  addRate,
  deleteRate,
  isClient
}) => {

  const [isEditing, setIsEditing] = useState(false);
  const [fieldToEdit, setFieldToEdit] = useState('');
  const [newValue, setNewValue] = useState('');
  /* const [showForm, setShowForm] = useState(false);*/

  const toggleToEdit = (field) => {
    setIsEditing(true);
    setFieldToEdit(field);
    setNewValue(field === 'name' ? theme.name : theme.description);
  };

  let navigate = useNavigate();

  const handleEdit = () => {
    editTheme(theme.id, fieldToEdit, newValue);
    setIsEditing(false);
    if (fieldToEdit === 'name') {
      let path = `/seance/${_.kebabCase(newValue)}`;
      navigate(path);
    }
  };

  const handleChange = (evt) => {
    setNewValue(evt.target.value);
  };

  const handleAddPictureOnTheme = (e) => {   
    e.preventDefault();
    const formData = new FormData();
    formData.append('file', newValue);
    var isMainPicture = false;
    if (e.target.id === 'mainPicture') {
      isMainPicture = true;
    }
    addPictureOnTheme(formData, theme.id, isMainPicture);
    setIsEditing(false);
  }

  let mainPicture = theme.pictures.find((picture) => picture.id === theme.mainPictureId);

  return (

    <div className='theme'>
        <div className='theme__imageAndTitle'>
          <div className='theme__imageAndTitle__title'>
            {isEditing && fieldToEdit === 'name' ? (
              <>
                <input className='theme__imageAndTitle__title__input' type='text' value={newValue} onChange={handleChange} />
                <button className='theme__imageAndTitle__title__valid' onClick={handleEdit}>OK</button>
              </>
            ) : (
              <>
                <p className='theme__imageAndTitle__title__intro' > Tout savoir sur la séance</p>
                <div>
                  {theme.name}
                  {isPhotographer && (
                    <i className='bi bi-pencil-fill theme__imageAndTitle__title__edit' onClick={() => toggleToEdit('name')} id='name'></i>
                  )}
                </div>
              </>
            )}
          </div>

          <div>
            {mainPicture ? (
                <Image publicId={mainPicture.publicId} cloudName={process.env.REACT_APP_CLN_CLOUD_NAME} className='theme__imageAndTitle__image'></Image>
            ) : (
              <img src='https://placeholder.pics/svg/400x500' width='100%' height='100%' alt='à venir' />
            )}
            {isPhotographer && (
              <div className='mb-5 text-center text-white'>
                <p>Ajouter/modifier la photo principale</p>
                <input type='file' onChange={(e) => setNewValue(e.target.files[0])} />
                <button onClick={handleAddPictureOnTheme} id='mainPicture' >OK</button>
              </div>
            )}
          </div>
        </div>
        <div className='theme__descriptionAndRates'>
          { !isEditing && (
            <div className='theme__descriptionAndRates__description'>
              {nl2br(theme.description)}
              {isPhotographer && (
                <i className='bi bi-pencil-fill ms-3 text-danger' onClick={() => toggleToEdit('description')} id='description'></i>
              )} 
            </div>
          )}
          
          {isEditing && fieldToEdit === 'description' && (
              <>
                <textarea value={newValue} onChange={handleChange} className='theme__descriptionAndRates__description__edit'/>
                <button className='myButton my-auto mx-3' onClick={handleEdit}>OK</button>
              </>
            )}
          
          <Rates theme={theme} isPhotographer={isPhotographer} addRate={addRate} deleteRate={deleteRate} />
        </div>
      
      <div className='theme__gallery'>
        <Gallery gallery={theme.pictures} layout={"columns"} columns={3} isPhotographer={isPhotographer} isClient={isClient} deletePicture={deletePicture} theme={theme}/>
      </div>

      { isPhotographer && (
        <div className='text-center text-white'>
          <p>Ajouter des photos à la galerie</p>
          <input type='file' onChange={(e) => setNewValue(e.target.files[0])} />
          <button onClick={handleAddPictureOnTheme}>OK</button>
        </div>
      )}

        
    </div>
  );
};

Theme.propTypes = {
};

// Valeurs par défaut pour les props
Theme.defaultProps = {
};
