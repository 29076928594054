import { connect } from 'react-redux';
import { Gallery } from '../../components/Gallery';

import { setFavorite, fetchShooting, validateFavourites } from '../../store/actions/shooting';
//import { deletePicture} from '../../store/actions/picture';


const mapStateToProps = (state, ownProps) => ({
    isPhotographer: state.auth.isPhotographer,
    isClient: state.auth.isClient,
    shooting: state.shooting.shooting,
    userId: state.auth.id,
    favouritesSelected: state.shooting.shooting.favouritesSelected,
});

const mapDispatchToProps = (dispatch, ownProps) => ({

    setFavorite: (pictureId, shootingId) => {
        dispatch(setFavorite(pictureId, shootingId));
    },

    /* deletePicture: (pictureId, shootingId, themeId) => {
        dispatch(deletePicture(pictureId, shootingId, themeId));
    }, */

    fetchShooting: (shootingId) => {
        dispatch(fetchShooting(shootingId));
    },

    validateFavourites: (shootingId, userId) => {
        dispatch(validateFavourites(shootingId, userId));
    }
  
});


export default connect(mapStateToProps, mapDispatchToProps)(Gallery);
