import React, { useEffect, useState } from 'react';

import './styles.scss';

export const Clients = ({
  addNewUser,
  clients,
  fetchClients,
}) => {
  
  const [showForm, setShowForm] = useState(false);
  const [newClient, setNewClient] = useState({
    newClientFirstName: '',
    newClientLastName: '',
    newClientEmail: '',
    newClientPassword: '',
    newClientAddress: '',
    newClientPostalCode: '',
    newClientCity: '',
    newClientCountry: '',
    newClientPhoneNumber: '',
  });

  useEffect(() => {
    fetchClients();
  }, [fetchClients]);

  const handleNewClient = async (evt) => {
    evt.preventDefault();
    await addNewUser(newClient);
    setNewClient({
      newClientFirstName: '',
      newClientLastName: '',
      newClientEmail: '',
      newClientPassword: '',
      newClientAddress: '',
      newClientPostalCode: '',
      newClientCity: '',
      newClientCountry: '',
      newClientPhoneNumber: '',
    });
    setShowForm(false);
  };

  const handleGenerateRandomPassword = (evt) => {
    evt.preventDefault();
    const randomPassword = Math.random().toString(36).slice(-8);
    setNewClient({
      ...newClient,
      newClientPassword: randomPassword,
    });
  };

  const toggleForm = () => {
    setShowForm(!showForm); 
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewClient({
      ...newClient,
      [name]: value,
    });
  };

  return (
    <div className='newClient'>
      {!showForm && (
        <button onClick={toggleForm} className='myButton m-auto my-2'>
          <i className="bi bi-plus"></i>
        </button>
      )}

      {showForm && (
        <form autoComplete='off' method='POST' className='newClient__form' onSubmit={handleNewClient}>
          <div className="container">
            <div className="row">
              <div className="col">
                <input
                  type="text"
                  name="newClientFirstName"
                  placeholder="Prénom"
                  className="newClient__form__input"
                  value={newClient.newClientFirstName}
                  onChange={handleChange}
                />
              </div>
              <div className="col">
                <input
                  type="text"
                  name="newClientLastName"
                  placeholder="Nom"
                  className="newClient__form__input"
                  value={newClient.newClientLastName}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <input
                  type="text"
                  name="newClientEmail"
                  placeholder="Email"
                  className="newClient__form__input"
                  value={newClient.newClientEmail}
                  onChange={handleChange}
                />
              </div>
              <div className="col d-flex">
                <input
                  type="text"
                  name="newClientPassword"
                  placeholder="Password"
                  className="newClient__form__input"
                  value={newClient.newClientPassword}
                  onChange={handleChange}
                />
                <button onClick={handleGenerateRandomPassword} className="mySmallButton ms-2">
                  Générer un mot de passe aléatoire
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <input
                  type="text"
                  name="newClientAddress"
                  placeholder="Adresse"
                  className="newClient__form__input"
                  value={newClient.newClientAddress}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <input
                  type="text"
                  name="newClientPostalCode"
                  placeholder="Code postal"
                  className="newClient__form__input"
                  value={newClient.newClientPostalCode}
                  onChange={handleChange}
                />
              </div>
              <div className="col">
                <input
                  type="text"
                  name="newClientCity"
                  placeholder="Ville"
                  className="newClient__form__input"
                  value={newClient.newClientCity}
                  onChange={handleChange}
                />
              </div>
              <div className="col">
                <input
                  type="text"
                  name="newClientCountry"
                  placeholder="Pays"
                  className="newClient__form__input"
                  value={newClient.newClientCountry}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <input
                  type="text"
                  name="newClientPhoneNumber"
                  placeholder="Numéro de téléphone"
                  className="newClient__form__input"
                  value={newClient.newClientPhoneNumber}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          <div className='newClient__form__buttons'>
            <button type='submit' className='myButton m-3'>
              Valider
            </button>
            <button type='button' onClick={toggleForm} className='myButton bg-danger m-3'>
              Annuler
            </button>
          </div>
        </form>
      )}


      <table className="table table-striped w-75 m-auto bg-light mt-4">
        <thead>
          <tr>
            <th scope="col">Nom</th>
            <th scope="col">Adresse</th>
            <th scope="col"> Email </th>
            <th scope="col">Numéro de téléphone</th>
            <th scope="col"> Mot de passe provisoire </th>
          </tr>
        </thead>
        <tbody>
          {clients.sort((a, b) => {
                if (a.createdAt < b.createdAt)
                  return 1;
                if (a.createdAt > b.createdAt)
                  return -1;
                return 0;
              }).map((client) => (
            <tr key={client.id}>
              <th scope="row">{client.user.firstName} {client.user.lastName}</th>
              <td> {client.address} - {client.postalCode} {client.city} </td>
              <td> {client.user.email} </td>
              <td> {client.phoneNumber} </td>
              <td> {client.user.password} </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

Clients.propTypes = {};

Clients.defaultProps = {};
