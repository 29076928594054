export const SAVE_SHOOTINGS = 'SAVE_SHOOTINGS';
export const SET_SHOOTING_ID = 'SET_SHOOTING_ID';
export const SET_FAVORITE = 'SET_FAVORITE';
export const VALIDATE_FAVORITES_MESSAGE = 'VALIDATE_FAVORITES_MESSAGE';
export const ADD_NEW_SHOOTING = 'ADD_NEW_SHOOTING';
export const DELETE_SHOOTING = 'DELETE_SHOOTING';
export const REFRESH_SHOOTING = 'REFRESH_SHOOTING';
export const FETCH_SHOOTING_BY_ID = 'FETCH_SHOOTING_BY_ID';
export const SAVE_SHOOTING = 'SAVE_SHOOTING';
export const VALIDATE_FAVOURITES = 'VALIDATE_FAVOURITES';
export const FETCH_SHOOTINGS_BY_USER = 'FETCH_SHOOTINGS_BY_USER';
export const UPDATE_SHOOTING = 'UPDATE_SHOOTING';


export const saveShootings = (shootings) => ({
  type: 'SAVE_SHOOTINGS',
  shootings,
});

export const setShootingId = (shootingId) => ({
  type: 'SET_SHOOTING_ID',
  shootingId,
});
export const setFavorite = (pictureId, shootingId) => ({
  type: 'SET_FAVORITE',
  pictureId,
  shootingId,
});
export const getValidateFavoritesMessage = (response, shootingId) => ({
  type: 'VALIDATE_FAVORITES_MESSAGE',
  response,
  shootingId,
});

export const addNewShooting = (newShooting) => ({
  newShooting,
  type: 'ADD_NEW_SHOOTING'
})

export const deleteShooting = (shootingId) => ({
  shootingId,
  type: 'DELETE_SHOOTING'
})

export const refreshShooting = (pictureId, shootingId) => ({
  pictureId,
  shootingId,
  type: 'REFRESH_SHOOTING'
})

export const fetchShooting = (id) => ({
  type: 'FETCH_SHOOTING_BY_ID',
  id,
});

export const saveShooting = (shooting) => ({
  type: 'SAVE_SHOOTING',
  shooting,
});

export const validateFavourites = (shootingId, userId) => ({
  type: 'VALIDATE_FAVOURITES',
  shootingId,
  userId,
});

export const fetchShootingsByUser = () => ({
  type: 'FETCH_SHOOTINGS_BY_USER',
});

export const updateShooting = (id, newNumberOfPictures) => ({
  type: 'UPDATE_SHOOTING',
  id,
  newNumberOfPictures,
});

