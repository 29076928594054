import { connect } from 'react-redux';
import { Clients } from '../../components/Clients';
import { addNewUser } from '../../store/actions/user';
import { fetchShooting, deleteShooting, addNewShooting, setShootingId, fetchShootingsByUser } from '../../store/actions/shooting';

import { fetchClientsOfPhotographer } from '../../store/actions/user';


const mapStateToProps = (state) => ({
  shootings: state.shooting.shootings,
  clients: state.user.clients,
  themes: state.theme.themes
});

const mapDispatchToProps = (dispatch) => ({

  fetchShooting: (shootingId) => {
    dispatch(fetchShooting(shootingId))
  },

  deleteShooting: (shootingId) => {
    dispatch(deleteShooting(shootingId))
  },

  addNewUser: (newClient) => {
    dispatch(addNewUser(newClient));
  },

  addNewShooting : (newShooting) => {
    dispatch(addNewShooting(newShooting));
  },
  
  setShootingId: (shootingId) => {
    dispatch(setShootingId(shootingId))
  },

  fetchClients: () => {
    const photographerId = localStorage.getItem('photographerId');
    dispatch(fetchClientsOfPhotographer(photographerId))
  },

  fetchShootingsByUser: () => {
    dispatch(fetchShootingsByUser())
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Clients);
