import React, { useState, useEffect } from 'react';
import { useNavigate, Navigate } from 'react-router-dom';
import {AdvancedImage} from '@cloudinary/react';
import {Cloudinary} from "@cloudinary/url-gen";

import Swal from 'sweetalert2'
import DatePicker from "react-datepicker";
import { formatDate } from '../../utils/formatDate';
import './styles.scss';
import 'react-datepicker/dist/react-datepicker.css';


export const Galleries = ({
  isLogged,
  shootings,
  firstConnect,
  isClient,
  isPhotographer,
  clients,
  addNewShooting,
  themes,
  fetchClients,
  deleteShooting,
  fetchShooting,
  fetchShootingsByUser,
}) => {

  const [theme, setTheme] = useState({ rates: [] });
  const [startDate, setStartDate] = useState(new Date());
  const [newShooting, setNewShooting] = useState({ date:'', nameOfGallery:'', clientId:'', themeId:'', numberOfPictures:'' })
  const [showForm, setShowForm] = useState(false); 
  
  useEffect(() => {
    if (isLogged && isPhotographer) {
      fetchClients();
      fetchShootingsByUser();
    }
  }, [
    fetchClients,
    fetchShootingsByUser,
    isLogged,
    isPhotographer,
  ]);

  let navigate = useNavigate();  

   // Create a Cloudinary instance and set your cloud name.
   const cld = new Cloudinary({
    cloud: {
      cloudName: process.env.REACT_APP_CLN_CLOUD_NAME,
    }
  });

  const handleShowShooting = (evt) => {
    fetchShooting(evt.target.id);
    navigate(`/galerie/${evt.target.id}`);
  };

  const handleDeleteShooting = async (evt) => {
    evt.preventDefault();
    Swal.fire({
      title: 'Es tu sûre ?',
      text: "Ceci est irréversible!",
      icon: 'warning',
      iconColor: '#b58d34',
      showCancelButton: true,
      cancelButtonText: 'Annuler',
      confirmButtonColor: '#b58d34',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, supprime!'
    }).then((result) => {
      if (result.isConfirmed) {
        deleteShooting(evt.target.id);
        Swal.fire({
          title: 'Supprimé!',
          text: 'La galerie a été supprimée.',
          icon: 'success',
          confirmButtonColor: '#b58d34',
        })
      }
    })
  };

  const handleNewShooting = (evt) => {
    if (newShooting.date === '') {
      newShooting.date = startDate;
    }
    try {
      addNewShooting(newShooting);
    } catch (error) {
      console.log(error);
    } finally {
      setNewShooting({ date:'', nameOfGallery:'', clientId:'', themeId:'', numberOfPictures:'' })
      setShowForm(!showForm);
    }
  };

  const toggleForm = () => {
    setShowForm(!showForm); 
  };

  return (
    <>
      { firstConnect && ( <Navigate to={`/temp`} /> )}
      { !isLogged && <Navigate to={`/login`} /> }

      { isLogged && isClient && 
        <div className='galleries text-center'>
          <h1 className='galleries__title'> Mes galeries </h1>
          { shootings.length === 0 && <p> Vous n'avez pas encore de galeries. Ou celles-ci ont expiré. Si tel est le cas et que vous souhaitez de nouveau récupérer vos photos, pas d'inquiétude, envoyez moi un petit message. </p>}
          <div className='galleries__galleries'>
            {shootings.map((shooting) => {
              return (
                <div key={shooting.id} className='galleries__gallery' onClick={handleShowShooting}>
                  {shooting.pictures.length > 0 ? (
                    <>
                      {shooting.pictures ? (
                        <AdvancedImage
                          cldImg={cld.image(shooting.pictures[0].publicId)}
                          className='galleries__gallery__picture'
                          alt={shooting.pictures[0].name}
                          id={shooting.id}
                        />
                      ) : (
                        <p> {shooting.nameOfGallery} - pas d'images </p>
                      )}
                      <p id={shooting.id} className='galleries__gallery__name'>
                        {shooting.nameOfGallery}
                      </p>
                    </>
                  ) : (
                    <p> {shooting.nameOfGallery} - pas d'images </p>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      }

      { isLogged && isPhotographer &&
      
        <div className='newGallery'>
          {!showForm && (
            <button onClick={toggleForm} className='myButton d-block m-auto my-2'>
              <i className="bi bi-plus"></i>
            </button>
          )}

          {showForm && (
            <>
              <div className='newGallery__form__container'>
                <div className='newGallery__form__container__element'>
                  <input
                    type='text'
                    placeholder="Nom de la galerie"
                    onChange={(e) =>
                      setNewShooting({
                        ...newShooting,
                        nameOfGallery: e.target.value,
                      })
                    }
                    className='newGallery__form__container__element__input'
                  />
                </div>

                <div className='newGallery__form__container__element'>
                  <select 
                    onChange={() =>
                      setNewShooting ({
                        ...newShooting,
                        clientId: parseInt(document.getElementById('client-select').value, 10)
                        })
                      } 
                    id="client-select" 
                    className='newGallery__form__container__element__select'
                  >

                    <option> Client </option>

                      { clients.map((client) =>
                        <option key={client.id} value={client.id}>{client.user.firstName} {client.user.lastName}</option>
                        )}
                  </select>
                </div>

              </div>

              <div className='newGallery__form__container'>

                <div className='newGallery__form__container__element'>
                  <select 
                    onChange={() => {
                      setTheme({
                        ...theme,
                        id: parseInt(document.getElementById('theme-select').value, 10),
                        rates: themes.find((theme) => theme.id === parseInt(document.getElementById('theme-select').value, 10)).rates
                      });

                      setNewShooting({
                        ...newShooting,
                        themeId: parseInt(document.getElementById('theme-select').value, 10)
                      })
                    }}
                    placeholder='Choisir un thème' 
                    id="theme-select"
                    className='newGallery__form__container__element__select'
                  >

                  <option> Thème </option>

                  {themes.map((theme) =>
                    <option key={theme.id} value={theme.id}> {theme.name} </option>
                    ) }
                  </select>

                  
                </div>

                <div className='newGallery__form__container__element'>
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => {
                      setStartDate(date);
                      setNewShooting({
                        ...newShooting,
                        date: date,
                        })
                      }
                    }
                    dateFormat="dd/MM/yyyy"
                    className='newGallery__form__container__element__select'
                  />
                </div>

                <div className='newGallery__form__container__element'>
                  <input
                    type='number'
                    placeholder="Nombre de photos"
                    onChange={(e) =>
                      setNewShooting({
                        ...newShooting,
                        numberOfPictures: e.target.value,
                      })
                    }
                    className='newGallery__form__container__element__input'
                  />
                </div>

              </div>

              <div className='newClient__form__buttons'>
                <button onClick={handleNewShooting} className='myButton m-3'>
                  Valider
                </button>
                <button type='button' onClick={toggleForm} className='myButton bg-danger m-3'>
                  Annuler
                </button>
              </div>
            </>
          )}

          <table className="table table-hover w-75 m-auto mt-4 bg-light">
            <thead>
                <tr>
                    <th scope="col">Client</th>
                    <th scope="col">Galerie photo</th>
                    <th scope="col">Thème</th>
                    <th scope="col">Date</th>
                    <th scope="col">Nombre de photos</th>
                    <th scope="col"></th>
                </tr>
            </thead>
            <tbody>
              { shootings.sort((a, b) => {
                if (a.createdAt < b.createdAt)
                  return 1;
                if (a.createdAt > b.createdAt)
                  return -1;
                return 0;
              }).map((shooting) =>
              <tr key={shooting.id}>
                <td id={shooting.id} onClick={handleShowShooting}> {shooting.client.user.firstName} {shooting.client.user.lastName}</td>
                <td id={shooting.id} onClick={handleShowShooting}> {shooting.nameOfGallery} </td>
                <td id={shooting.id} onClick={handleShowShooting}> {shooting.theme.name} </td>
                <td id={shooting.id} onClick={handleShowShooting}> Le {formatDate(shooting.date)} </td>
                <td id={shooting.id} onClick={handleShowShooting}> {shooting.numberOfPictures} </td>
                <td>
                  <i id={shooting.id} onClick={handleDeleteShooting} className="bi bi-trash3"></i>
                </td>
              </tr>
              )}
            </tbody>
          </table>

        </div>
      }
    </>
  );
};

Galleries.propTypes = {
};

Galleries.defaultProps = {
};
