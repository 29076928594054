import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import emailjs from '@emailjs/browser';


import './styles.scss';

export const Login = ({
  handleLogin,
  isLogged,
  showErrorMessage,
  errorMessage,
  forgotPassword,

}) => {
  const [email, setEmail] = useState('');
  const [forgotEmail, setForgotEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showForgotPasswordForm, setShowForgotPasswordForm] = useState(false);

  useEffect(() => emailjs.init(process.env.REACT_APP_EMAILJS_PUBLIC_KEY), []);

  const handleSubmitLogin = (evt) => {
    evt.preventDefault();
    handleLogin(email, password);
  };

  const handleSubmitForgot = async (evt) => {
    evt.preventDefault();
    forgotPassword(forgotEmail);
    Swal.fire({
      title: 'Réussi !',
      text: 'Un email vous a été envoyé afin de réinitialiser votre mot de passe.',
      icon: 'success',
      confirmButtonText: 'Cool',
    });
    setForgotEmail('');
    setShowForgotPasswordForm(false);
    
  };

  const location = useLocation();

  useEffect(() => {
    window.scroll(0, 0);
  }, [location]);


  if (isLogged) {
      return <Navigate to="/galeries" />;
    
  }

  return (
    <div className="login">
      {showForgotPasswordForm ? (
        <div className="forgotPassword">
          <form autoComplete="off" className="forgotPassword__form" onSubmit={handleSubmitForgot}>
            <input
              type="email"
              name="forgotEmail"
              placeholder="Entrez votre adresse email"
              required
              onChange={(evt) => setForgotEmail(evt.target.value)}
              value={forgotEmail}
              className="forgotPassword__form__input"
            />
    
            <button
              type="submit"
              className="myButton mx-auto mt-2 "
            >
              Réinitialiser le mot de passe
            </button>
    
            <button onClick={ () => setShowForgotPasswordForm(false)} className="mySmallButton mx-auto mt-4"> retour </button>
          </form>
        </div>
      ) : (
        <form autoComplete="off" onSubmit={handleSubmitLogin} className="login__form">
          <p className="login__form__intro">
            Cet espace est destiné aux personnes ayant déjà réalisé une séance photo avec moi. <br/> Vous y retrouverez vos clichés et pourrez les télécharger.
          </p>

          <input
            type="text"
            name="email"
            placeholder="Adresse mail"
            onChange={(evt) => setEmail(evt.target.value)}
            value={email}
            className="login__form__input"
          />

          <input
            type="password"
            name="password"
            placeholder="Mot de passe"
            onChange={(evt) => setPassword(evt.target.value)}
            value={password}
            className="login__form__input"
          />

          {showErrorMessage && <p className="login__form__error">{errorMessage}</p>}

          <button className="mySmallButton mx-auto mt-2" onClick={() => setShowForgotPasswordForm(true)}>
            Mot de passe oublié ?
          </button>

          <button type="submit" className="myButton d-block mx-auto mt-3">
            Connexion
          </button>
        </form>
      )}
    </div>
  );
};
