import React from 'react';
import './styles.scss';


export const IntroTextHome = () => {

  return (
    <div className='introTextHome'>
      <p className='introTextHome__text'> 
      <i className="bi bi-chevron-double-left"></i> Chaque séance photo est une occasion de créer des images qui reflètent votre individualité et votre bonheur. Mon approche est empreinte de douceur, de patience et de bienveillance, afin de vous mettre a l’aise et de capturer des expressions naturelles et sincères. <i className="bi bi-chevron-double-right"></i>
      </p>
    </div>
  
  )};

IntroTextHome.propTypes = {
};

IntroTextHome.defaultProps = {
};