  import {
    SAVE_ALL_CLIENTS_OF_PHOTOGRAPHER,
  } from '../actions/user';

  import {
    LOGOUT,
  } from '../actions/authentification';

  export const initialState = {
    clients: [],
  };

  const reducer = (state = initialState, action = {}) => {
    switch (action.type) {

        case SAVE_ALL_CLIENTS_OF_PHOTOGRAPHER:
          return {
            ...state,
            clients: action.clients,
          };

      case LOGOUT:
        return initialState;


      default:
        return state;
    }
  };

  export default reducer;
