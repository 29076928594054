export const DELETE_RATE = 'DELETE_RATE';
export const ADD_RATE = 'ADD_RATE';


export const deleteRate = (rate, themeId) => ({
    type: DELETE_RATE,
    rate,
    themeId,
});

export const addRate = (rate, themeId) => ({
    type: ADD_RATE,
    rate,
    themeId,
});