import { connect } from 'react-redux';
import { AddPicture } from '../../components/AddPicture';

import { uploadImage } from '../../store/actions/picture';



const mapStateToProps = (state, ownProps) => ({
});

const mapDispatchToProps = (dispatch, ownProps) => ({

    uploadImage: (formData, shootingId, config) => {
        dispatch(uploadImage(formData, shootingId, config));
    },
});


export default connect(mapStateToProps, mapDispatchToProps)(AddPicture);
