import { connect } from 'react-redux';
import { Account } from '../../components/Account';

const mapStateToProps = (state) => ({
  user: state.auth,
  isClient: state.auth.isClient,  
});

const mapDispatchToProps = (dispatch) => ({
  
 
  
});

export default connect(mapStateToProps, mapDispatchToProps)(Account);