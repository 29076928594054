import { connect } from 'react-redux';
import { Login } from '../../components/Login';
import { forgotPassword, login } from '../../store/actions/authentification';

const mapStateToProps = (state) => ({
  showErrorMessage: state.auth.showErrorMessage,
  errorMessage: state.auth.errorMessage,
  isLogged: state.auth.isLogged,
  firstConnect: true,
  client: state.auth.client,
  photographer: state.auth.photographer,
  messageForgot: state.auth.messageForgot,
  successMessage: state.auth.successMessage,
});

const mapDispatchToProps = (dispatch) => ({

  handleLogin: (email, password) => {
    dispatch(login(email, password));
  },

  forgotPassword: (forgotEmail) => {
    dispatch(forgotPassword(forgotEmail));
  },

});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
