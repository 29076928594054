import { connect } from 'react-redux';
import { Theme } from '../../components/Theme';
import { editTheme } from '../../store/actions/theme';
import { addPictureOnTheme } from '../../store/actions/picture';
import { deletePicture } from '../../store/actions/picture';
import { deleteRate, addRate } from '../../store/actions/rate';




const mapStateToProps = (state, ownProps) => ({
    isPhotographer: state.auth.isPhotographer,
    isClient: state.auth.isClient,
});

const mapDispatchToProps = (dispatch, ownProps) => ({

    editTheme: (themeId, field, value) => {
        dispatch(editTheme(themeId, field, value));
    },

    addPictureOnTheme : (pictureId, themeId, isMainPicture) => {
        dispatch(addPictureOnTheme(pictureId, themeId, isMainPicture));
    },

    deletePicture: (pictureId, shootingId, themeId) => {
        dispatch(deletePicture(pictureId, shootingId, themeId));
    },

    addRate : (rate, themeId) => {
        dispatch(addRate(rate, themeId));
    },

    deleteRate : (rate, themeId) => {
        dispatch(deleteRate(rate, themeId));
    }

});

export default connect(mapStateToProps, mapDispatchToProps)(Theme);
