import { connect } from 'react-redux';
import { Shooting } from '../../components/Shooting';
import { fetchShooting, updateShooting } from '../../store/actions/shooting';
import { deletePicture} from '../../store/actions/picture';


const mapStateToProps = (state, ownProps) => ({
    isPhotographer: state.auth.isPhotographer,
    isClient: state.auth.isClient,
    shooting: state.shooting.shooting,
    isLoading: state.shooting.isLoading,
    favouritesSelected: state.shooting.shooting.favouritesSelected
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    
    fetchShooting: (id) => {
        dispatch(fetchShooting(id));
    },
    
    updateShooting: (id, newNumberOfPictures) => {
        dispatch(updateShooting(id, newNumberOfPictures));
    },

    deletePicture: (pictureId, shootingId, themeId) => {
        dispatch(deletePicture(pictureId, shootingId, themeId));
    }
});


export default connect(mapStateToProps, mapDispatchToProps)(Shooting);
