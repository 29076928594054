import React from 'react';
//import PropTypes from 'prop-types';

import { FirstPart } from './FirstPart';
import { IntroTextHome } from './IntroTextHome';
import { LongImageHome } from './LongImageHome';

import './styles.scss';
import { ItsMeHome } from './ItsMeHome';


export const Home = ({isLogged}) => {

  return (
  
    <div className='home'> 
      <FirstPart isLogged={isLogged} />
      <IntroTextHome />
      <LongImageHome />
      <ItsMeHome />
    </div>

  
  )
};
